import $ from 'jquery';
import Util from './utility';

let isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

function getIEVersion() {
    var ua = window.navigator.userAgent;
    if (ua.indexOf("Trident/7.0") > 0)
        return 11;
    else if (ua.indexOf("Trident/6.0") > 0)
        return 10;
    else if (ua.indexOf("Trident/5.0") > 0)
        return 9;
    else
        return 0;  // not IE9, 10 or 11
}

function isEdge() {
    try {
        return window.navigator.userAgent.indexOf("Edge") > -1;
    } catch (e) {
        return false;
    }
}

function parseValue(s: string) {
    if (s.indexOf('"') === 0) {
        // This is a quoted cookie as according to RFC2068, unescape...
        s = s.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\');
    }

    try {
        var pluses = /\+/g;
        // Replace server-side written pluses with spaces.
        // If we can't decode the cookie, ignore it, it's unusable.
        // If we can't parse the cookie, ignore it, it's unusable.
        s = decodeURIComponent(s.replace(pluses, ' '));
        return JSON.parse(s);
    } catch (e) { }
}

export default class Browser {
    static isMobile = isMobile;
    static isEdge = isEdge;
    static getIEVersion = getIEVersion;

    static setStorageItem(key: string, value: any) {
        try {
            var valueToSave = Browser.encode(JSON.stringify(value));
            window.localStorage.setItem(Browser.encode(key), valueToSave);
        } catch (e) {
            return;
        }
    }

    static getStorageItem(key: string, defaultValue: any = null): any {
        try {
            //console.log(encode(key), window.localStorage.getItem(encode(key)));
            var value = window.localStorage.getItem(Browser.encode(key));
            if (value) {
                value = parseValue(Browser.decode(value));
                if (value)
                    return value;
            }

            return defaultValue;
        } catch (e) {
            return null;
        }
    }

    static getStorageBool(key: string, defaultValue: boolean = false) {
        defaultValue = defaultValue || false;
        const value = Browser.getStorageItem(key, defaultValue);
        if (value && value != undefined)
            return value === true || value == 'true' || value == '1';
        return false;
    }

    static dumpParentWindows(header) {
        let previousElem = null;
        let elem = document.getElementById("placeholder-image");
        if (!elem)
            elem = document.body;

        let idx = 0;
        while (elem && elem != previousElem) {
            Debug.log(header, "parent", idx, elem, previousElem);
            previousElem = elem;
            elem = elem.parentElement;
            idx++;
        }
    }

    static resizeText($elem, width) {
        // hard code font-sizes
        if (width <= 240) {
            $elem.css("font-size", 10);
        }
    }

    static rescaleRootFontSize(desiredWidth, defaultWidth, defaultFontSize, minFontSize = 4) {
        try {
            // Adjust root font-size to allow rem css font-size elements to be scaled appropriately
            let ratio = desiredWidth / defaultWidth;
            let fontSize = defaultFontSize * ratio;
            fontSize = Math.max(minFontSize, fontSize);
            $('html').css('font-size', fontSize + 'px');
        } catch (e) {
            Debug.error("rescaleRootFontSize", e);
        }
    }

    static copyToClipboard(value: string, message: string = null) {
        message = message || 'Copied to clipboard';

        let $textArea = $('#copyToClipboard');
        $textArea.text(value);

        let textArea = <any>$textArea[0];
        $textArea.removeAttr('disabled');
        textArea.focus();
        textArea.select();

        document.execCommand("Copy");
        Util.showToast(message);

        $textArea.attr('disabled', 'disabled');
    }

    static encode(s: string) {
        return encodeURIComponent(s);
    }

    static decode(s: string) {
        return decodeURIComponent(s);
    }

    static _getRouteParam($route: any, key: string, defaultValue: any) {
        if ($route) {

            if ($route.params) {
                let value = $route.params[key] || null;
                if (value && value != '')
                    return value;
            }

            if ($route.query) {
                let value = $route.query[key] || null;
                if (value && value != '')
                    return value;
            }

            return Browser.getParam(key, defaultValue);
        }
    }

    static getRouteParam<T>($route: any, key: string, defaultValue: T): T {
        let value = Browser._getRouteParam($route, key, defaultValue);
        if (value && value != '') {
            if (typeof defaultValue == 'boolean') {
                return <T><any>(value == '1' || value == 'true');
            }

            if (typeof defaultValue == 'number') {
                return <T><any>(parseInt(value, 10));
            }
        }
        return <T>value;
    }

    static getRouteParamEscaped($route: any, key: string, defaultValue: any): string {
        let value = Browser._getRouteParam($route, key, defaultValue);

        if (value && value != defaultValue)
            return decodeURI(value);

        return value;
    }

    static getBoolRouteParam($route: any, key: string, defaultValue: boolean): boolean {
        let value = Browser._getRouteParam($route, key, defaultValue);
        return value == 'true' || value == '1';
    }

    static getIntRouteParam($route: any, key: string, defaultValue: number): number {
        let value = Browser._getRouteParam($route, key, defaultValue);
        return parseInt(value, 10);
    }

    static getParam(key: string, defaultValue: any) {
        try {
            key = key.toLowerCase();

            let queryStr = window.location.search || null;

            // vue router doesn not populate the window.location.search
            if (!queryStr) {
                queryStr = window.location.href || '';
                let idx = queryStr.indexOf('?')
                if (idx == -1)
                    return defaultValue;
                queryStr = queryStr.substr(idx);
            }

            var params = queryStr.replace("?", "");
            var idx = queryStr.toLowerCase().replace("?", "").indexOf(key + '=');
            if (idx != -1) {
                idx += key.length + 1;
                var idxEnd = params.indexOf('&', idx);
                if (idxEnd == -1)
                    idxEnd = params.length;
                var value = params.substr(idx, idxEnd - idx);
                if (value && value != "")
                    return value;
            }
        } catch (e) {
            console.error("getParam error", e);
        }
        return defaultValue;
    }

    static getParamEscaped(key: string, defaultValue: any = null) {
        let value = Browser.getParam(key, defaultValue);
        if (value && value != defaultValue) {
            return decodeURI(value);
        }
        return value;
    }

    static getBoolParam(key: string, defaultValue: boolean = false) {
        try {
            var value = Browser.getParam(key, "");
            if (value == "")
                return defaultValue;
            return value == "true" || value == "1";
        } catch (e) {
            console.error("getBoolParam error", e);
        }
        return defaultValue;
    }

    static getIntParam(key: string, defaultValue: number = 0) {
        try {
            var value = Browser.getParam(key, "");
            if (value == "")
                return defaultValue;
            return parseInt(value, 10);
        } catch (e) {
            console.error("getIntParam error", e);
        }
        return defaultValue;
    }


}
