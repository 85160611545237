import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Browser from '@/support/browser';
import SigninUtil from '@/support/signinUtil';
import Util from '@/support/utility';
import { EventBus } from '@/support/eventBus';
import { IShowSettings } from '@/interfaces';
import { ServerDataModule } from '@/store/serverData';
import { StudioModule } from '@/store/studio';

@Component
export default class TopBarComponent extends Vue {
    options = {
        noAdmin: Browser.getBoolParam('noAdmin', false),
        noOwner: Browser.getBoolParam('noOwner', false),
        noTeamMember: Browser.getBoolParam('noTeamMember', false),
    };

    get serverData() {
        return ServerDataModule.serverData;
    }

    get isAdmin() {
        return !this.options.noAdmin && (this.serverData.isAdmin || StudioModule.studio.isAdmin);
    }

    get isOwner() {
        return !this.options.noOwner && StudioModule.studio.isOwner;
    }

    get isTeamMember() {
        return !this.options.noOwner && this.serverData.isTeamMember;
    }

    get showHelp() {
        return this.isAdmin || this.isOwner || this.isTeamMember;
    }

    get signOutTitle() {
        return 'Sign out   :  ' + this.serverData.fullName +
            '\nEmail        :  ' + this.serverData.email +
            '\nProvider   :  ' + this.serverData.providerName;
    }

    get showSettings() {
        let route = <any>this.$route;
        if (!route.meta || !route.meta.settings || !route.meta.settings.component)
            return false;

        return !route.meta.settings.ownerRequired || this.isAdmin || this.isOwner;
    }

    showSettingsWizard() {
        let route = <any>this.$route;
        if (!route.meta || !route.meta.settings || !route.meta.settings.component) {
            Util.showError('Cannot show settings');
            throw 'Route does not have settings';
        }

        let model: IShowSettings = {
            name: route.meta.settings.component,
            parent: this,
        };

        EventBus.showSettingsWizard(model);
    }

    clearSignin() {
        SigninUtil.clear();
    }
}
