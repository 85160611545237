//import { Promise } from 'es6-promise';
import moment from 'moment';
import toastr from 'toastr';
import { ServerDataModule } from '@/store/serverData';

//let es6polyfill = <any>require('es6-promise');
//es6polyfill.polyfill();
//require('isomorphic-fetch');

interface IPostOptions {
    method: string,
    url: string,
    data: any,
    json: boolean;
    jsonResponse: boolean;
    showError: boolean;
    headers: any;
    noCredentials: boolean;
}

let DefaultPostOptions: IPostOptions = {
    method: 'POST',
    url: null,
    data: {},
    json: true,
    jsonResponse: true,
    showError: false,
    headers: {},
    noCredentials: false,
};

declare global {
    interface Array<T> {
        forEachAsync(callback: Function);
    }
};

export default class Util {

    static stringCompare(left: string, right: string) {
        if (left && right) {
            return 0 == left.localeCompare(right, 'en', { sensitivity: 'base' });
        }

        if (!left && !right)
            return true;

        return false;
    }

    static getRouteInfo(href: string) {
        var info = {
            href: href,
            relativeHref: href,
            controller: <string>null,
            title: <string>null,
        };

        var idx = href.indexOf("#/");
        if (idx != -1) {
            href = href.substr(idx + 2);

            if (href) {
                info.href = href;

                var parts = href.split('/');
                info.relativeHref = parts.join('/');
                info.controller = parts[0];
                info.title = parts.join(' ');
            }
        }
        return info;
    }

    // -_./\ {space} are all seperators
    static sanitizeName(value: string) {
        if (value)
            return value.replace(/([^ a-z0-9._-]+)/gi, '-').replace(/\s*$/, "");
        return null;
    }

    // -_./\ {space} are all seperators
    static sanitizeCompany(value: string) {
        if (value)
            return value.replace(/([^ a-z0-9!@#&()._-]+)/gi, '-').replace(/\s*$/, "");
        return null;
    }

    // -_./\ {space} are all seperators
    static sanitizeSentence(value: string) {
        if (value)
            return value.replace(/([^ a-z0-9\'!@#$%&*()._/-]+)/gi, '-').replace(/\s*$/, "");
        return null;
    }

    // -_./\ are all seperators
    static sanitizeId(value: string) {
        if (value)
            return value.replace(/([^a-z0-9._-]+)/gi, '');
        return null;
    }

    static sanitizeEmail(value: string) {
        if (value)
            return value.replace(/([^@a-z0-9._-]+)/gi, '');
        return null;
    }

    static sanitizeIpAddress(value: string) {
        if (value)
            return value.replace(/([^0-9.]+)/gi, '');
        return null;
    }

    /**
     * Sanitize fiter name - all valid special characters: #$%()*+-.<=>@^_`{}~:
     * Playable but need escape codes: #%)+ - fails to delete though.
     * But only use which don't require html escape codes
     */
    static sanitizeFilterName(value: string) {
        if (value)
            //return value.replace(/([^a-z0-9#$%()*+.<=>@\^_`{}:~-]+)/gi, '');
            return value.replace(/([^a-z0-9$(*.<=>@^_`{}:~-]+)/gi, '');
        return null;
    }

    // 08/30/2016 8:48 PM
    static sanitizeDateTime(value: string) {
        if (value) {
            //return value.replace(/[^\d\d?\/\d\d?\/\d\d\d\d \d\d?:\d\d (AM|PM)]/gi, '')
            var result = value.match(/\d\d?\/\d\d?\/\d\d\d\d \d\d?:\d\d (AM|PM)/gi);
            if (result && result.length > 0)
                return result[0];
        }
        return null;
    }

    static isValidEmail(value: string) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    }

    static isValidDateTime(value: string) {
        return value && value.match(/\d\d?\/\d\d?\/\d\d\d\d \d\d?:\d\d (AM|PM)/gi) != null;
    }

    static isValidName(value: string) {
        return value
            && value.length >= 2
            && value.length <= 30
            && value == Util.sanitizeName(value);
    }

    static isValidNameOrNull(value: string) {
        return !value ||
            (value.length >= 2
                && value.length <= 30
                && value == Util.sanitizeName(value));
    }

    static isValidId(value: string) {
        return value
            && value.length >= 2
            && value.length <= 30
            && value == Util.sanitizeId(value);
    }

    static isValidIdOrNull(value: string) {
        return !value ||
            (value.length >= 2
                && value.length <= 30
                && value == Util.sanitizeId(value));
    }

    // format date for makeDateTimePicker in Home/Index.cshtml
    static formatDate(dateTime) {
        if (!moment || !dateTime)
            return null;

        return moment(dateTime.replace("T", " ")).format('MM/DD/YYYY hh:mm a');
    }

    static formatNumberWithCommas(value: number) {
        let parts = value.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts[0];
    }

    static timestamp() {
        if (!moment)
            return null;

        let now = new Date();
        return moment(now).format('MM/DD/YYYY hh:mm:ss a');
    }

    static duration(end, start) {
        let mEnd = moment(end);
        let mStart = moment(start);
        let diff = mEnd.diff(mStart);
        return moment.utc(diff).format("HH:mm:ss");
    }

    static fetchUrl(options: IPostOptions = DefaultPostOptions) {
        //console.log("fetchUrl", options.url, options.data, options);

        options.data = options.data || {};

        // Object.assign for Typescript
        let headers = {
            ...options.headers || {},
            RequestVerificationToken: window.sessionStorage.getItem('requestVerificationToken'),
            TabSessionId: window.sessionStorage.getItem('tabSessionId'),
        };

        headers['Accept'] = 'application/json, text/plain, */*';

        console.log(`XR n ${options.noCredentials}`)
        console.log(`XR data ${options.data ? JSON.stringify(options.data) : options.data}`)
        console.log(`XR url ${options.url ? JSON.stringify(options.url) : options.url}`)
        if (options.json) {
            headers['Content-Type'] = 'application/json';
        }
        console.log("fetchUrl", options.url, headers);
     
        return fetch(options.url,
            <any>{
                method: options.method,
                headers: headers,
                body: options.json ? JSON.stringify(options.data) : options.data,
                cache: 'no-cache',
                credentials: options.noCredentials ? null : 'same-origin',
                mode: 'cors',
            })
            .then(response => {
                console.log('fetchUrl', options.url, response);

                if (!response.ok) {
                    console.error('fetchUrl', options.url, response.statusText, options.url, options.url, response);

                    /* 
                    if (response.status == 400) {
                        return {
                            status: false,
                            message: 'Not Authorized'
                        };
                    }
                    */

                    return null;
                }

                if (options.jsonResponse) {
                    let data = response.json();
                    //console.log('fetchUrl', options.url, data, response);
                    if (data == null) {
                        console.error('fetchUrl', options.url, response);
                    }
                    return data;
                }

                return response.body || response.statusText;
            }).catch(error => {
                console.error('fetchUrl', options.url, error);
                return null;
            });
    }

    static postUrl(url: string, data: any = {}, headers: any = {}, noCredentials: boolean = false, options: any = null) {

        options = options || <IPostOptions><any>({ ...{}, ...DefaultPostOptions });
     
        options.url = url || null;
        options.data = data || {};
        options.noCredentials = noCredentials || false;
        options.headers = headers || {};
        console.log(`XR info: ${options}`)
        return Util.fetchUrl(options);
    }

    static getUrl(url: string, data: any = {}, headers: any = {}, noCredentials: boolean = false, options: any = null) {

        options = options || <IPostOptions><any>({ ...{}, ...DefaultPostOptions });

        options.url = url || null;
        options.data = data || {};
        options.noCredentials = noCredentials || false;
        options.headers = headers || {};
       
        return Util.fetchUrl(options);
    }

    static createPromise<T>(callback: Function) {
        return new Promise<T>((resolve, reject) => {
            callback(resolve, reject);
        });
    };

    static failedPromise<T>(data: T) {
        return new Promise<T>((resolve, reject) => {
            reject(data);
        });
    };

    static successPromise<T>(data: T) {
        return new Promise<T>((resolve, reject) => {
            resolve(data);
        });
    };

    static expiredPromise() {
        return Util.failedPromise('expired');
    }

    static loadScript(url: string, callback?: any) {
        callback = callback || (() => { });

        var scriptTag = document.createElement('script');
        scriptTag.src = url;
        scriptTag.onload = () => callback();
        (<any>scriptTag).onreadystatechange = () => callback();
        document.body.appendChild(scriptTag);
    }

    static showToast(message: string, error: boolean = false, timeOut = '3000') {
        Util._showToast(message, error, timeOut, 'toast-bottom-center');
    }

    static showTopToast(message: string, error: boolean = false, timeOut = '3000') {
        Util._showToast(message, error, timeOut, 'toast-top-center');
    }

    static _showToast(message: string, error: boolean = false, timeOut = '3000', positionClass = 'toast-bottom-center') {
        error = error || false;
        //console.log("showToast", message, error);

        toastr.clear();
        (<any>toastr).options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": positionClass,
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": timeOut,
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };
        if (error)
            toastr.error(message);
        else
            toastr.success(message);
    }

    static getRandomInt(max = 4294967295) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    static dumpJson(data: any) {
        return JSON.stringify(data, <any>' ', 3);
    }

    static showError(error: string) {
        Debug.error("showError", error);
        Util.showToast(error, true);
    }

    static async forEachAsync(callback) {
        for (let index = 0; index < this.length; index++) {
            await callback(this[index], index, this);
        }
    }

    static randomString(length: number = 10) {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length);
    }
}

if (!Array.prototype.forEachAsync) {
    Array.prototype.forEachAsync = <any>(Util.forEachAsync);
}
