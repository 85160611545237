/// <reference path="../DTO.d.ts" />
/// <reference path="external.d.ts" />
/// <reference path="interfaces.d.ts" />

import DebugModule from '@/support/debug'; // Import early to get debugging turned on

if (ServerData.debugMode || false) {
    console.log(
        `*************************************************
main', ${window.location.href}
*************************************************`);

    /**
     * Initialize Debug before all other modules 
     * */
    DebugModule.ensureCalled();
}

import 'animate.css/animate.min.css';
import 'bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import 'font-awesome-animation/dist/font-awesome-animation.min.css';
import 'toastr/build/toastr.min.css';
import App from './views/app/app.vue';
import BlockUI from 'vue-blockui';
import VModal from 'vue-js-modal';
import Vue from 'vue';
import router from './router';
import store from '@/store/store';

Vue.config.productionTip = false;
Vue.use(VModal, { dialog: true });
Vue.use(BlockUI);

Vue.component('addEveryoneAtMicrosoft', () => import('@/wizards/permissions/addEveryoneAtMicrosoft/addEveryoneAtMicrosoft.vue'));
Vue.component('addNewCategory', () => import('@/dialogs/addNewCategory/addNewCategory.vue'));
Vue.component('addNewUser', () => import('@/wizards/permissions/addNewUser/addNewUser.vue'));
Vue.component('changeCreationDate', () => import('@/wizards/changeCreationDate/changeCreationDate.vue'));
Vue.component('expired', () => import('@/views/expired/expired.vue'));
Vue.component('notAuthorized', () => import('@/views/notAuthorized/notAuthorized.vue'));
Vue.component('player', () => import('@/components/player/player.vue'));
Vue.component('prompt', () => import('@/components/prompt/prompt.vue'));
Vue.component('removePermission', () => import('@/wizards/permissions/removePermission/removePermission.vue'));
Vue.component('video-buttons', () => import('@/components/videoButtons/videoButtons.vue'));
Vue.component('vue-grid', () => import('@/components/vue-grid/vue-grid.vue'));
Vue.component('wizard', () => import('@/components/wizard/wizard.vue'));

document.getElementById('body-loading').style.display = 'none';
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');