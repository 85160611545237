Debug.debugMode = ServerData.debugMode;

export default class DebugModule {
    static initialized = false;

    static ensureCalled() { }

    static initializeDebug() {
        if (!Debug.debugMode || false)
            return;

        if (console && console.log) {
            try {
                Debug.log = console.log.bind(window.console);
            } catch (err) {
                Debug.log = console.log;
            }
            try {
                Debug.log("Debug Mode");
            } catch (err) {
                Debug.log = () => { };
            }
        }
        if (console && console.error) {
            try {
                Debug.error = console.error.bind(window.console);
            } catch (err) {
                Debug.error = console.error;
            }
            try {
                // Debug.error("");
            } catch (err) {
                Debug.error = () => { };
            }
        }
        if (Debug.log) {
            Debug.dumpCallStack = () => {
                var stack = new Error().stack;
                Debug.log("PRINTING CALL STACK");
                Debug.log(stack);
            }
        }

        if (!Debug.log)
            Debug.log = () => { };
        if (!Debug.error)
            Debug.error = () => { };
        if (!Debug.dumpCallStack)
            Debug.dumpCallStack = () => { };

        Debug.setDebugModule = function (key, value, item) {
            let name = key;
            value = value || null;
            let windowObj = <any>window;

            if (item) {
                name = 'g_' + key[0].toUpperCase() + key.substr(1);
                if (!window[name])
                    window[name] = {} as any;

                window[name][item] = value;
                return;
            }

            switch (key.toLowerCase()) {
                case 'app': name = 'g_App'; break;
                case 'serverdata': name = 'g_ServerData'; break;
                case 'wizard': name = 'g_Wizard'; break;

                case 'player': {
                    if (!windowObj.g_Player)
                        windowObj.g_Player = {};

                    windowObj.g_Player[item] = value;
                    return;
                }

                case 'player_ams': {
                    if (!windowObj.g_Player)
                        windowObj.g_PlayerAms = {};

                    windowObj.g_PlayerAms[item] = value;
                    return;
                }

                default: {
                    name = 'g_' + key[0].toUpperCase() + key.substr(1);
                    break;
                }
            }

            window[name] = value;
            //Debug.log('setDebugModule', name);
        }

    }
}

if (!DebugModule.initialized) {
    DebugModule.initialized = true;
    DebugModule.initializeDebug();
}
