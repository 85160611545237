import { Module, VuexModule, Mutation, Action, MutationAction, getModule } from 'vuex-module-decorators'
import { orderBy, sortedIndexBy } from 'lodash';
import { EventBus } from '@/support/eventBus';
import PlayerApi from '@/support/playerApi';
import store from '@/store/store';
import { ServerDataModule } from './serverData';

@Module({ dynamic: true, store, name: 'category' })
export class Category extends VuexModule {
    categories: SUR.CategoryDto[] = [];

    @MutationAction({ mutate: ['categories'] })
    async fetchCategories(method: string):Promise<any> {
        try {
            if (!ServerDataModule.serverData.isSignedIn) {
                return {
                    categories: []
                };
            }

            Debug.log('fetchCategories', method);
            let data = await PlayerApi.categoriesList();
            data = orderBy(data, ['order', 'name']);
            Debug.log('fetchCategories', method, data);
            (<any>this).commit('commit_categories', data);

            return {
                categories: orderBy(data, ['order', 'name'])
            };
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('categories - fetchCategorys Failed aquire categories: ' + message);

            return {
                categories: []
            };
        }
    }

    /*****************************************************************
     * Studio Mutations
     *
     *****************************************************************
     */
    @Mutation
    private commit_categories(categories: SUR.CategoryDto[]) {
        this.categories.splice(0, this.categories.length, ...categories);
        Debug.log('categories commit_categories', this.categories.length);
    }

    /*****************************************************************
     * Category mutations
     *
     *****************************************************************
     */
    @Action({ commit: 'commit_create_category' })
    async createCategory(category: SUR.CategoryDto) {
        if (!ServerDataModule.serverData.isSignedIn)
            return null;

        return await PlayerApi.createCategory(category);
    }

    @Action({ commit: 'commit_edit_category' })
    async editCategory(category: SUR.CategoryDto) {
        if (!ServerDataModule.serverData.isSignedIn)
            return null;

        return await PlayerApi.editCategory(category);
    }

    @Action({ commit: 'commit_delete_category' })
    async deleteCategory(category: SUR.CategoryDto) {
        if (!ServerDataModule.serverData.isSignedIn)
            return null;

        return await PlayerApi.deleteCategory(category);
    }

    @Mutation
    private commit_create_category(category: SUR.CategoryDto) {
        let idx = this.categories.findIndex(item => item.id == category.id || item == category);
        if (idx != -1) {
            Debug.error('commit_create_category already exists', category);
            throw "Category already exists";
        }

        idx = sortedIndexBy(this.categories, category, item => item.name);
        if (idx != -1) {
            this.categories.splice(idx, 0, category);
        } else {
            this.categories.push(category);
        }

        EventBus.Instance.$emit(EventBus.CategoriesChanged, category);

        return {
            categories: this.categories
        };
    }

    @Mutation
    private commit_edit_category(category: SUR.CategoryDto) {
        let idx = this.categories.findIndex(item => item.id == category.id || item == category);
        if (idx == -1) {
            Debug.error('commit_edit_category missing category', category);
            throw "Missing category " + category.name;
        }

        let found = this.categories[idx];
        Object.assign(found, category);

        EventBus.Instance.$emit(EventBus.CategoriesChanged, category);

        return {
            categories: this.categories
        };
    }

    @Mutation
    private commit_delete_category(category: SUR.CategoryDto) {
        let idx = this.categories.findIndex(item => item.id == category.id || item == category);
        if (idx == -1) {
            Debug.error('category removeCategory missing category', category);
            throw "Missing category " + category.name;
        }

        this.categories.splice(idx, 1);
        EventBus.Instance.$emit(EventBus.CategoriesChanged, category);

        return {
            categories: this.categories
        };
    }
}

export const CategoryModule = getModule(Category);
Debug.setDebugModule('Category', CategoryModule);
