import VueRouter from 'vue-router';
import { differenceWith, findIndex, orderBy, pullAllWith } from 'lodash';
import Util from './utility';
import Globals from '@/globals';
import { VideoPlayerState } from '@/globals';
import { IVideo, IStudy } from '@/interfaces';
import { routes } from '@/router';
import { ServerDataModule } from '@/store/serverData';

const DebugMergeStudyList = false;

export default class PlayerUtil {
    static videoStateToString(state) {
        switch (state) {
            case VideoPlayerState.NONE:
                return "none";
            case VideoPlayerState.LOADING:
                return "loading";
            case VideoPlayerState.READY:
                return "ready";
            case VideoPlayerState.PLAYING:
                return "playing";
            case VideoPlayerState.PAUSED:
                return "paused";
            case VideoPlayerState.ENDED:
                return "ended";
            case VideoPlayerState.ERROR:
                return "error";
            default:
                return "unknown";
        }
    }

    static synchronizeVideos(oldVideos, newVideos) {
        let removeOld = differenceWith(oldVideos, newVideos, PlayerUtil.compareVideoStrings);
        let addNew = differenceWith(newVideos, oldVideos, PlayerUtil.compareVideoStrings);

        if (removeOld.length > 0) {
            pullAllWith(oldVideos, removeOld);

            if (DebugMergeStudyList)
                Debug.log('Removed', removeOld.length, removeOld);
        }

        // Update existing videos from response videos
        oldVideos.forEach(video => {
            let newVideo = oldVideos.find(item => PlayerUtil.compareVideoStrings(item, video));
            if (newVideo == null) {
                Debug.error('Copying video missing', video.id, video.title);
                return;
            }

            Object.assign(video, newVideo);
        });

        if (addNew.length > 0) {
            addNew.forEach(video => {
                oldVideos.push(video);
            });
            if (DebugMergeStudyList)
                Debug.log('Added', addNew.length, addNew);
        }

        let sortedVideos = orderBy(oldVideos, ['creationDate', 'title', 'filter', 'filterName'], ['desc', 'asc', 'asc', 'asc'])

        if (DebugMergeStudyList) {
            Debug.log('sortedVideos', sortedVideos);
        }

        for (let idx = 0; idx < oldVideos.length; idx++) {
            let video = oldVideos[idx];

            let tgtIndex = sortedVideos.indexOf(video);
            if (tgtIndex == -1) {
                Debug.error('Missing sorted video', PlayerUtil.getVideoSignature(video));
                continue;
            }

            if (tgtIndex == idx)
                continue;

            oldVideos.splice(idx, 1, {});
            oldVideos.splice(tgtIndex, 0, video);
            oldVideos.splice(idx, 1);
        }

    }

    static getStudyNames(studyList: IStudy[]) {
        let names = [];
        studyList.forEach(study => {
            names.push(study.name);
        });
        return names.join(' , ');
    }

    static synchronizeStudies(studyList: IStudy[], responseStudies: IStudy[]) {
        if (DebugMergeStudyList) {
            Debug.log('synchronizeStudies removedStudies', studyList.length, responseStudies.length);
            Debug.log('synchronizeStudies studyList       -------- ', PlayerUtil.getStudyNames(studyList));
            Debug.log('synchronizeStudies responseStudies -------- ', PlayerUtil.getStudyNames(responseStudies));
        }

        let removeOld = differenceWith(studyList, responseStudies, (left, right) => {
            return left.name == right.name;
        });

        let addNew = differenceWith(responseStudies, studyList, (left, right) => {
            return left.name == right.name;
        });

        if (DebugMergeStudyList) {
            Debug.log('synchronizeStudies removeOld       -------- ', PlayerUtil.getStudyNames(removeOld));
            Debug.log('synchronizeStudies addNew          -------- ', PlayerUtil.getStudyNames(addNew));
        }

        if (removeOld.length > 0) {
            pullAllWith(studyList, removeOld, (left, right) => {
                return left.name == right.name;
            });

            if (DebugMergeStudyList)
                Debug.log('synchronizeStudies removedStudies', removeOld.length, removeOld);
        }

        studyList.forEach(study => {
            let newStudy = responseStudies.find(item => item.name == study.name);
            if (!newStudy) {
                Debug.error('synchronizeStudies missing study', study.name);
                return;
            }

            if (DebugMergeStudyList)
                Debug.log('synchronizing study', study.name, study.videos.length, newStudy.videos.length, study.videos);

            PlayerUtil.synchronizeVideos(study.videos, newStudy.videos);
        });

        addNew.reverse();
        addNew.forEach(study => {
            studyList.splice(0, 0, study);
        });

        if (DebugMergeStudyList) {
            Debug.log('synchronizeStudies added/removed', studyList.length, responseStudies.length);
            Debug.log('synchronizeStudies studyList       -------- ', PlayerUtil.getStudyNames(studyList));
        }
    }

    static tailSynchronizeVideos(oldVideos: IVideo[], newVideos: IVideo[], studyName: string) {
        let removeOld = differenceWith(oldVideos, newVideos, PlayerUtil.compareVideos);
        let addNew = differenceWith(newVideos, oldVideos, PlayerUtil.compareVideos);

        removeOld.forEach(video => {
            let idx = findIndex(oldVideos, item => PlayerUtil.compareVideos(item, video));
            if (idx == -1) {
                Debug.error("tailSynchronizeVideos missing old video", studyName, video);
                return;
            }

            if (DebugMergeStudyList)
                Debug.log('tailSynchronizeVideos removing old video', studyName, idx, video);
            oldVideos.splice(idx, 1);
        });

        addNew.forEach(video => {
            if (DebugMergeStudyList)
                Debug.log('tailSynchronizeVideos adding new video', studyName, 'end', video);
            oldVideos.push(video);
        });
    }

    static addVideoListPrivateMembers(videos: IVideo[]) {
        videos.forEach(video => {
            PlayerUtil.addVideoPrivateMemebers(video);
        });
    }

    static addVideoPrivateMemebers(video: IVideo) {
        video.token = null;
        video.player = null;
        video.busy = false;
        video.downloadUrl = null;
        video.testPlaybackUrl = null;
        video.disableDownload = false;
        video.dontPlay = false;
        video.downloadProgress = 0;
        video.captionsProgress = 0;
        video.autoMuted = false;

        video.captions = video.captions || [];
        video.captions.forEach(caption => {
            caption.url = window.location.origin + '/DownloadCaptions/' + video.studio + '/' + caption.hash;
        });

        if (video.captions.length > 0)
            video.downloadCaptionsUrl = video.captions[0].url;

        if (video.title && video.tooltip) {
            if (!video.tooltip.startsWith(video.title)) {
                video.tooltip = video.title + ' - ' + video.tooltip;
            }
        }
    }

    static isSpecialGroup(name: string) {
        if (!name)
            return false;
        else if (name != Util.sanitizeCompany(name))
            return false;

        return name.toLowerCase() == Globals.EveryoneAtMicrosoft.toLowerCase() ||
            name.toLowerCase() == Globals.GlobalEveryone.toLowerCase();
    }

    static getVideoSignature(video: IVideo) {
        return video.id + ' -- ' + video.title + ' -- ' + (video.filter || '') + ' -- ' + (video.filterName || '') + ' -- ' + video.creationDate;
    }

    static compareVideos(left: IVideo, right: IVideo) {
        if (!left || !right)
            return false;

        return left.id == right.id &&
            left.title == right.title &&
            left.filter == right.filter &&
            left.filterName == right.filterName &&
            left.creationDate == right.creationDate;
    }

    static compareVideoStrings(left: IVideo, right: IVideo, verbose = false) {
        if (!left || !right)
            return false;

        if (verbose)
            Debug.log('compareVideoStrings', left.id, right.id, PlayerUtil.getVideoSignature(left), PlayerUtil.getVideoSignature(right));

        return left.id == right.id &&
            left.title == right.title &&
            left.filter == right.filter &&
            left.filterName == right.filterName;
    }

    static sortCompareItems(left: any, right: any) {
        if (left == right)
            return 0;

        return (left < right) ? -1 : 1;
    }

    static sortCompareVideos(left: IVideo, right: IVideo) {
        if (left.filter && right.filter) {
            let streamResult = PlayerUtil.sortCompareItems(left.id, right.id);
            if (streamResult == 0) {
                let result = PlayerUtil.sortCompareItems(left.title, right.title);
                if (result == 0) {
                    return PlayerUtil.sortCompareItems(left.filterName, right.filterName);
                }
                return result;
            }
        }

        let creationDateResult = PlayerUtil.sortCompareItems(left.creationDate, right.creationDate);
        if (creationDateResult == 0) {
            let result = PlayerUtil.sortCompareItems(left.title, right.title);
            return result;
        }

        return -1 * creationDateResult;
    }

    static videoToStreamApiDto(video: IVideo) {
        let model: SUR.StreamApiDto = {
            studio: video.studio,
            study: video.study,
            stream: video.id,
            filter: video.filter,
            filterName: video.filterName,
            title: video.title,
            recreate: false,
        };
        return model;
    }

    static redirectToStudio(router: any, studio: string) {
        PlayerUtil.redirectToStudioView(router, '/Studio', studio);
    }

    static redirectToRequestAccess(router: any, studio: string) {
        PlayerUtil.redirectToStudioView(router, '/RequestAccess', studio);
    }

    static redirectToError(router: any) {
        if (ServerData.debugging || ServerDataModule.serverData.debugging) {
            console.error('Skip redirectToError');
            return;
        }

        PlayerUtil.redirectToView(router, '/Error');
    }

    static redirectToNotAuthorized(router: any) {
        if (ServerData.debugging || ServerDataModule.serverData.debugging) {
            console.error('Skip redirectToNotAuthorized');
            return;
        }

        PlayerUtil.redirectToView(router, '/NotAuthorized');
    }

    static redirectToView(router: VueRouter, view: string, studio: string = null, replace: boolean = false) {
        Debug.log('redirectToView', view, studio);

        let route = routes.find(item => item.path == view || item.path.startsWith(view));
        if (!route) {
            Debug.error('missing route', view);
            return
        }

        route = Object.assign({}, route);
        if (studio) {
            route.path = route.path.replace(':studio', studio);
        }
        if (replace)
            router.replace(route);
        else
            router.push(route);

        Debug.log('redirectToView', route.path, view, studio);
    }

    static redirectToStudioView(router: any, view: string, studio: string) {
        Debug.log('redirectToStudioView', view, studio);

        let route = routes.find(item => item.path.startsWith(view));
        if (route) {
            route = Object.assign({}, route);
            route.path = view + '/' + studio + '/';
            router.push(route);
        }
    }

    static dumpVideos(header: string, videos) {
        Debug.log(header, videos.length);
        videos.forEach((video, idx) => {
            Debug.log(idx, video.title, video.id);
        });
    }
}

