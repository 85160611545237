import { IPermissionDto, ICategoryDto, IVideo } from './interfaces';

export default class Globals {
    public static XboxResearch = "Xbox Research Streaming";
    public static EveryoneAtMicrosoft = "(Everyone At Microsoft)";
    public static GlobalEveryone = "(Everyone Globally)";
    public static AllStudios = "_All_Studios_";
    public static AllStudiosTitle = "(All Studios)";
    public static Microsoft = "Microsoft";

    public static get NewServerViewModel() {
        let serverViewModel: IServerViewModel = {
            expired: false,
            hostName: null,
            isAdmin: false,
            isTeamMember: false,
            isDeveloper: false,
            isSignedIn: false,
            isLimitedBrowser: false,
            debugMode: false,
            debugging: false,
            email: null,
            fullName: null,
            providerName: null,

            mute: false,
            notAuthorized: false,
            hideTopBar: false,
        };

        return Object.assign<IServerViewModel>(serverViewModel, <any>ServerData);
    }

    public static get NewStudio() {
        let model: SUR.AzureStudioDto = {
            studioId: 0,
            studio: null,
            title: null,
            isOwner: false,
            isAdmin: false,
            isDeveloper: false,
            logoFileName: null,
            backgroundFileName: null,
            categoryId: 0,
            category: null,
            categoryOrder: 0,
            disabled: false,
            liveOnly: false,
            noSecurity: false,
            isAllStudio: false,
            logoUrl: null,
            backgroundUrl: null,
            hasLogoImage: false,
            hasBackgroundImage: false,
        };

        return Object.assign<SUR.AzureStudioDto>({}, model);
    }

    public static get NewCategory() {
        let model: SUR.CategoryDto = {
            id: 0,
            name: null,
            order: 0,
            disabled: false
        };

        return Object.assign<SUR.CategoryDto>({}, model);
    }

    public static get NewICategoryDto() {

        let model: ICategoryDto = {
            id: 0,
            name: null,
            order: 0,
            disabled: false,
            errors: [],
            busy: false,
            canMoveUp: false,
            canMoveDown: false,
        };

        return Object.assign<ICategoryDto>({}, model);
    }

    public static get NewPlaylist() {
        let model: SUR.PlayListModel = {
            hash: null,
            studio: null,
            lastModifiedTime: null,
            studies: [],
            liveVideos: [],
            playtests: [],
            videoCount: 0,
            liveVideoCount: 0,
            vodVideoCount: 0,
        };
        return Object.assign<SUR.PlayListModel>({}, model);
    }

    public static get NewPermission() {
        let model: IPermissionDto = {
            id: 0,
            studio: null,
            email: null,
            workEmail: null,
            title: null,
            company: null,
            provider: 'microsoftaccount',
            isGroup: false,
            isOwner: false,
            isAdmin: false,
            isAllStudio: false,
            roleType: null,
            accessType: null,
            role: 'user',
            studioTitle: null,
            busy: false
        };
        return model;
    }

    public static get NewRequestAccess() {
        let model: SUR.RequestAccessDto = {
            //creationDate: null,
            confirmationId: null,
            //result: 0,
            approved: false,
            confirmed: false,
            isEmployee: false,
            pendingApprovalId: 0,
            businessJustification: null,
            changedBy: null,
            company: null,
            email: null,
            fullName: null,
            provider: null,
            studio: null,
            studioTitle: null,
            workEmail: null,
            noAuth: false,
            sendGridEmail: null,
            sendGridFrom: null
        };

        return Object.assign<SUR.RequestAccessDto>({}, model);
    }

    public static get NewVideo() {

        let model: IVideo = {
            study: null,
            studio: null,
            title: null,
            tooltip: null,
            id: null,
            isLive: false,
            creationDate: 0,
            streamUrl: null,
            captions: [],
            filter: null,
            filterName: null,
            azure: false,

            // Local data
            testPlaybackUrl: null,
            token: null,
            player: null,
            busy: false,
            downloadUrl: null,
            disableDownload: false,
            dontPlay: false,
            downloadCaptionsUrl: null,
            downloadProgress: 0,
            captionsProgress: 0,
            autoMuted: false,
        };

        return Object.assign<IVideo>({}, model);
    }


}

export interface ErrorType {
    message:string
}

// ReSharper disable InconsistentNaming
export enum VideoPlayerState {
    NONE = 0,
    LOADING = 1,
    READY = 2,
    PLAYING = 3,
    PAUSED = 4,
    ENDED = 5,
    ERROR = 6,
}
// ReSharper restore InconsistentNaming

