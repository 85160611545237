import Globals from '@/globals';
import PlayerUtil from './playerUtil';
import Util from './utility';
import { IVideo } from '@/interfaces';
import { ServerDataModule } from '@/store/serverData';

export default class PlayerApi {

    /* ************************************************************** */
    /* ************************************************************** */
    /* Player APIs                                                    */
    /* ************************************************************** */
    /* ************************************************************** */
    static getStreamUrl(video: IVideo) {
        let params = PlayerUtil.videoToStreamApiDto(video);

        return PlayerApi.postApiRequest<SUR.StreamValidation>('getStreamUrl', '/api/Player/Stream', params);
    }

    static checkStream(video: IVideo) {
        let params = PlayerUtil.videoToStreamApiDto(video);

        return PlayerApi.postApiRequest<SUR.StudyAsset>('checkStream', '/api/Player/StreamExists', params);
    }

    static deleteVideo(video: IVideo) {
        let params = PlayerUtil.videoToStreamApiDto(video);;

        return PlayerApi.postApiRequest<SUR.StudyAsset>('deleteVideo', '/api/Player/DeleteVideo', params);
    }

    static recreateCaptions(video: IVideo, recreate: boolean) {
        let params = PlayerUtil.videoToStreamApiDto(video);
        params.recreate = recreate;

        return PlayerApi.postApiRequest<SUR.BackgroundJobDto>('recreateCaptions', '/api/Player/RecreateCaptions', params);
    }

    // ReSharper disable once InconsistentNaming
    static recreateMP4(video: IVideo, recreate: boolean) {
        let params = PlayerUtil.videoToStreamApiDto(video);
        params.recreate = recreate;

        return PlayerApi.postApiRequest<SUR.BackgroundJobDto>('recreateMP4', '/api/Player/RecreateMP4', params);
    }

    static renameAsset(video: IVideo) {
        let params = PlayerUtil.videoToStreamApiDto(video);

        return PlayerApi.postApiRequest<SUR.StudyAsset>('renameAsset', '/api/Player/RenameAsset', params);
    }

    static resetAsset(video: IVideo) {
        let params = PlayerUtil.videoToStreamApiDto(video);

        return PlayerApi.postApiRequest<SUR.StudyAsset>('resetAsset', '/api/Player/ResetAsset', params);
    }

    static resetStudioAssets(studio: string) {
        let params = {
            studio: studio,
            stream: 'unused',
        };

        return PlayerApi.postApiRequest<SUR.StreamApiDto>('resetStudioAssets', '/api/Player/ResetStudioAssets/' + studio, params);
    }

    static getUploadUrl(studio: string, study: string, streamName: string, title: string) {
        let params = {
            studio: studio,
            study: study,
            stream: streamName,
            title: title,
        };

        return PlayerApi.postApiRequest<SUR.UploadUrlDto>('getUploadUrl', '/api/Player/GetUploadUrl', params);
    }

    static getUploadTitles(studio: string, videos: SUR.StreamApiDto[]) {
        let params = {
            studio: studio,
            streams: videos,
        };

        return PlayerApi.postApiRequest<SUR.StreamListApiDto>('getUploadTitles', '/api/Player/GetUploadTitles', params);
    }

    static encodeUploadedVideo(uploadInfo: SUR.UploadUrlDto) {
        let params = uploadInfo;

        return PlayerApi.postApiRequest<SUR.BackgroundJobDto>('encodeUploadedVideo', '/api/Player/EncodeUploadedVideo', params);
    }

    static checkBackgroundJob(encodeInfo: SUR.BackgroundJobDto) {
        let params = <any>encodeInfo;

        return PlayerApi.postApiRequest<SUR.BackgroundJobDto>('checkBackgroundJob', '/api/Player/CheckBackgroundJob', params);
    }

    static uploadVideosList(studio: string) {
        return PlayerApi.postApiRequest<SUR.BackgroundJobDto[]>('uploadVideosList', '/api/Player/UploadVideosList/' + studio, {});
    }

    static playList(model: SUR.PlaylistDto) {
        return PlayerApi.postApiRequest<SUR.RefreshResponseDto>('playList', '/api/Player/PlayList', model);
    }

    static liveAssets(model: SUR.PlaylistDto) {
        return PlayerApi.postApiRequest<SUR.StudyAsset[]>('getLiveAssets', '/api/Player/GetLiveAssets', model);
    }

    static createFilter(model: SUR.CreateFilterDto) {
        let params = model;

        return PlayerApi.postApiRequest<SUR.StreamApiDto>('createFilter', '/api/Player/CreateFilter', params);
    }

    static loginSession(studio: string) {
        return PlayerApi.postApiRequest<SUR.StreamApiDto>('loginSession', '/api/Player/LoginSession/' + studio, {});
    }

    /* ************************************************************** */
    /* ************************************************************** */
    /* Studio APIs                                                    */
    /* ************************************************************** */
    /* ************************************************************** */

    static studioList(studio: string = null) {
        let params = {
        };

        let studioArg = studio ? '/' + studio : '';

        return PlayerApi.postApiRequest<SUR.AzureStudioDto[]>('studioList', '/api/Studio/StudioList' + studioArg, params);
    }

    static createStudio(studio: SUR.AzureStudioDto) {
        return PlayerApi.postApiRequest<SUR.AzureStudioDto>('addNewStudio', '/api/Studio/CreateStudio', studio);
    }

    static editStudio(studio: SUR.AzureStudioDto) {
        return PlayerApi.postApiRequest<SUR.AzureStudioDto>('editStudio', '/api/Studio/EditStudio', studio);
    }

    static turnOffStudio(studio: SUR.AzureStudioDto) {
        return PlayerApi.postApiRequest<SUR.AzureStudioDto>('turnOffStudio', '/api/Studio/TurnOffStudio', studio);
    }

    static uploadLogo(file: any) {
        let params = file;

        return PlayerApi.postApiRequest<SUR.AzureStudioDto>('uploadLogo', '/api/Studio/UploadLogo', params, {
            method: 'POST',
            url: '/api/Studio/UploadLogo',
            data: params,
            json: false,
            jsonResponse: true,
            showError: false,
            headers: null,
            noCredentials: false,
        });
    }

    static uploadBackground(file: any) {
        let params = file;

        return PlayerApi.postApiRequest<SUR.AzureStudioDto>('uploadBackground', '/api/Studio/UploadBackground', params, {
            method: 'POST',
            url: '/api/Studio/UploadBackground',
            data: params,
            json: false,
            jsonResponse: true,
            showError: false,
            headers: null,
            noCredentials: false,
        });
    }

    /* ************************************************************** */
    /* ************************************************************** */
    /* Permissions APIs                                               */
    /* ************************************************************** */
    /* ************************************************************** */
    static getPendingApprovals(studio: string) {
        let params = {
            studio: studio,
        };

        return PlayerApi.postApiRequest<SUR.PendingApprovalDto[]>('getPendingApprovals', '/api/Permissions/PendingApprovalsList', params);
    }

    static approvePendingApproval(approval: SUR.PendingApprovalDto) {
        let params = approval;

        return PlayerApi.postApiRequest<SUR.PendingApprovalDto>('approvePendingApproval', '/api/Permissions/ApprovePendingApproval', params);
    }

    static denyPendingApproval(approval: SUR.PendingApprovalDto) {
        let params = approval;

        return PlayerApi.postApiRequest<SUR.PendingApprovalDto>('denyPendingApproval', '/api/Permissions/DenyPendingApproval', params);
    }

    static permissionsList(studio: string, limit: number = 0) {
        let params = {
            studio: studio,
            limit: limit,
        };

        return PlayerApi.postApiRequest<SUR.PermissionsListResponseDto>('permissionsList', '/api/Permissions/PermissionsList/' + (studio || ''), params);
    }

    static removePermission(studio: string, permission: SUR.PermissionDto) {
        let params = {
            studio: studio,
            permission: permission,
        };

        return PlayerApi.postApiRequest<SUR.PermissionDto>('removePermission', '/api/Permissions/RemovePermission', params);
    }

    static async addPermissionWorker(studio: string, newUser) {
        let isGroup = newUser.provider == 'group';
        if (isGroup) {
            newUser.provider = 'aad';
        }

        let permission = {
            id: 0,
            studio: studio,
            email: newUser.email || null,
            workEmail: newUser.workEmail || null,
            title: newUser.name || null,
            company: newUser.company || null,
            provider: newUser.provider || null,
            isGroup: isGroup || false,
            isOwner: newUser.role == 'owner',
            isAdmin: false,
            roleType: null,
            accessType: null,
            busy: true,
            isAllStudio: false,
        };

        if (PlayerUtil.isSpecialGroup(permission.title)) {
            permission.email = null;
            permission.workEmail = null;
            permission.company = null;
            permission.isGroup = true;
            permission.isOwner = false;

            if (newUser.name.toLowerCase() != Globals.EveryoneAtMicrosoft.toLowerCase())
                permission.provider = null;
        }

        try {
            let response = await PlayerApi.addPermission(studio, permission);

            Debug.log('addPermissionWorker', response, permission);
            return response;
        } catch (err) {
            let message = err.message || 'ERROR';
            Util.showError('Failed to add permission for ' + permission.title + " error: " + message);
            return null;
        } finally {
            permission.busy = false;
        }
    }

    static addPermission(studio: string, permission: SUR.PermissionDto) {
        let params = {
            studio: studio,
            permission: permission,
        };

        return PlayerApi.postApiRequest<SUR.PermissionDto>('addPermission', '/api/Permissions/AddPermission', params);
    }

    static requestAccess(params: SUR.RequestAccessDto) {
        return PlayerApi.postApiRequest<SUR.RequestAccessDto>('requestAccess', '/api/Permissions/RequestAccess', params);
    }

    static requestAccessData(studio: string) {
        return PlayerApi.postApiRequest<SUR.RequestAccessDto>('requestAccessData', '/api/Permissions/RequestAccessData/' + studio, {});
    }

    static requestAccessConfirmationData(id: string) {
        return PlayerApi.postApiRequest<SUR.RequestAccessDto>('requestAccessConfirmationData', '/api/Permissions/RequestAccessConfirmationData/' + id, {});
    }

    static checkStudioAccess(studio: string) {
        let params = <SUR.StudioNameDto>{
            studio: studio,
        };

        return PlayerApi.postApiRequest<SUR.StudioAccessResponseDto>('checkStudioAccess', '/api/Permissions/CheckStudioAccess', params);
    }

    /* ************************************************************** */
    /* ************************************************************** */
    /* Category APIs                                                  */
    /* ************************************************************** */
    /* ************************************************************** */

    static categoriesList() {
        let params = {
        };

        return PlayerApi.postApiRequest<SUR.CategoryDto[]>('categoriesList', '/api/Category/CategoriesList', params);
    }

    static createCategory(category: SUR.CategoryDto) {
        let params = category;

        return PlayerApi.postApiRequest<SUR.CategoryDto>('createCategory', '/api/Category/CreateCategory', params);
    }

    static editCategory(category: SUR.CategoryDto) {
        let params = category;

        return PlayerApi.postApiRequest<SUR.CategoryDto>('editCategory', '/api/Category/EditCategory', params);
    }

    static deleteCategory(category: SUR.CategoryDto) {
        let params = category;

        return PlayerApi.postApiRequest<SUR.CategoryDto>('deleteCategory', '/api/Category/DeleteCategory', params);
    }

    static moveCategoryUp(category: SUR.CategoryDto) {
        let params = category;

        return PlayerApi.postApiRequest<SUR.CategoryDto>('moveCategoryUp', '/api/Category/MoveCategoryUp', params);
    }

    static moveCategoryDown(category: SUR.CategoryDto) {
        let params = category;

        return PlayerApi.postApiRequest<SUR.CategoryDto>('moveCategoryDown', '/api/Category/MoveCategoryDown', params);
    }

    /* ************************************************************** */
    /* ************************************************************** */
    /* Admin APIs                                                     */
    /* ************************************************************** */
    /* ************************************************************** */

    static resetAssets(studio: string = '') {
        let params = {};
        return PlayerApi.postApiRequest<SUR.AzureStudioDto>('resetAssets', '/api/Admin/ResetAssets/' + studio, params);
    }

    static resetStudios() {
        let params = {};
        return PlayerApi.postApiRequest<SUR.AzureStudioDto>('resetStudios', '/api/Admin/ResetStudios', params);
    }

    /* ************************************************************** */
    /* ************************************************************** */
    /* Video                                                          */
    /* ************************************************************** */
    /* ************************************************************** */

    static videoList(studio: string = null, limit: number = 0) {
        let params = {
            studio: studio,
            limit: limit
        };
        return PlayerApi.postApiRequest<SUR.VideoDto[]>('videos', '/api/Video/VideoList/' + (studio || ''), params);
    }

    static changeVideoCreationDate(video: SUR.VideoDto) {
        let params = video;
        return PlayerApi.postApiRequest<SUR.VideoDto>('changeVideoCreationDate', '/api/Video/ChangeVideoCreationDate', params);
    }

    static deleteAdminVideo(video: SUR.VideoDto) {
        let params = <any>video;
        Debug.log('deleteAdminVideo', Util.dumpJson(params));

        return PlayerApi.postApiRequest<SUR.VideoDto>('deleteAdminVideo', '/api/Video/DeleteVideo', params);
    }

    /* ************************************************************** */
    /* ************************************************************** */
    /* Upload                                                         */
    /* ************************************************************** */
    /* ************************************************************** */

    static uploadList(studio: string = null, study: string = null, limit: number = 0) {
        let params = {
            studio: studio,
            study: study,
            limit: limit
        };
        return PlayerApi.postApiRequest<SUR.UploadListResponseDto>('uploads', '/api/Uploads/UploadList/' + (studio || ''), params);
    }

    /* ************************************************************** */
    /* ************************************************************** */
    /* Usage                                                          */
    /* ************************************************************** */
    /* ************************************************************** */

    static usageList(studio: string = null, allData = false, limit = 0, externalUsersOnly = false) {
        let params = {
            studio: studio,
            allData: allData,
            limit: limit,
            externalUsersOnly: externalUsersOnly
        };
        return PlayerApi.postApiRequest<SUR.PlayerUsageDto[]>('usageList', '/api/Usage/UsageList/' + (studio || ''), params);
    }

    /* ************************************************************** */
    /* ************************************************************** */
    /* Misc                                                           */
    /* ************************************************************** */
    /* ************************************************************** */

    static postApiRequest<T>(method, url, params: any = {}, options: any = null) {
        try {
            if (ServerData.expired || ServerDataModule.serverData.expired)
                throw new Error('EXPIRED');

            let headers = {
                RequestVerificationToken: window.sessionStorage.getItem('requestVerificationToken'),
                TabSessionId: window.sessionStorage.getItem('tabSessionId'),
            };

            return Util.postUrl(url, params, headers, false, options).then(data => {
                return PlayerApi.processApiRespose<T>(method, url, data);
            });

        } catch (e) {
            Debug.error(method + " error", e);
            throw e;
        }
    }

    static processApiRespose<T>(method: string, url: string, data: SUR.Success<T>) {
        //Debug.log('processApiResponse', method, url, data);

        if (!data) {
            Debug.error(method, url, 'NO_DATA');
            throw new Error('NO_DATA');
        }

        if (data.expired) {
            PlayerApi.handleExpiredResponse();
            throw new Error('EXPIRED');
        }

        if (data.message == 'Not Authorized') {
            PlayerApi.handleNotAuthorizedResponse();
            throw new Error('NOT_AUTHORIZED');
        }

        if (!data.status) {
            Debug.error(method, url, data.message || 'FAILED');
            throw new Error(data.message || 'FAILED');
        }

        if (!data.result) {
            Debug.error(method, url, 'NO_RESPONSE');
            throw new Error('NO_RESPONSE');
        }

        return data.result;
    }

    public static handleExpiredResponse() {
        if (ServerDataModule.serverData.expired) return;
        ServerData.expired = true;

        let serverData = Object.assign({}, ServerDataModule.serverData);
        serverData.expired = true;
        ServerDataModule.editServerData(serverData);

        Debug.error('EXPIRED **************');
        Debug.error('EXPIRED **************');
        Debug.error('EXPIRED **************');
        Debug.error('EXPIRED **************');
        Debug.error('EXPIRED **************');
        Debug.error('EXPIRED **************');
        Debug.error('EXPIRED **************');
        Debug.error('EXPIRED **************');
    }

    public static handleNotAuthorizedResponse() {
        if (ServerDataModule.serverData.notAuthorized) return;

        let serverData = Object.assign({}, ServerDataModule.serverData);
        serverData.notAuthorized = true;
        ServerDataModule.editServerData(serverData);

        Debug.error('NOT_AUTHORIZED **************');
        Debug.error('NOT_AUTHORIZED **************');
        Debug.error('NOT_AUTHORIZED **************');
        Debug.error('NOT_AUTHORIZED **************');
        Debug.error('NOT_AUTHORIZED **************');
        Debug.error('NOT_AUTHORIZED **************');
        Debug.error('NOT_AUTHORIZED **************');
        Debug.error('NOT_AUTHORIZED **************');
    }

}

