import Vue from 'vue';
import { IShowSettings } from '@/interfaces';
const eventBusInstance = new Vue();

export const EventBus = {
    CategoriesChanged: "Category.categoriesChanged",
    PermissionsChanged: "Permissions.permissionsChanged",
    ShowSettingsWizard: 'ShowSettingsWizard',
    ServerDataChanged: 'ServerDataModules.serverDataChanged',
    StudiosChanged: 'StudioModules.studiosChanged',
    VideosChanged: "Videos.videosChanged",

    Instance: eventBusInstance,

    showSettingsWizard(model: IShowSettings) {
        eventBusInstance.$emit(EventBus.ShowSettingsWizard, model);
    }
};

/**
 *  EventBus Usage
import { EventBus } from '@/support/eventBus';

EventBus.Instance.$emit('StudyModules.studysChanged', study);

EventBus.Instance.$on('StudyModules.studysChanged', study => {
    do my thing
});

*/