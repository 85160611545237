import Vue from 'vue';
import Browser from '@/support/browser';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import PlayerUtil from '@/support/playerUtil';
import Util from './utility';
import { CategoryModule } from '@/store/category';
import { ServerDataModule } from '@/store/serverData';
import { StudioModule } from '@/store/studio';

export default class StudioPageBase extends Vue {

    baseOptions = {
        noAdmin: Browser.getBoolParam('noAdmin', false),
        noOwner: Browser.getBoolParam('noOwner', false),
        noTeamMember: Browser.getBoolParam('noTeamMember', false),
    };

    baseKey: string = null;
    alias: string = null;
    private useExistingStudio = false;
    private requireStudio = false;

    get serverData() {
        return ServerDataModule.serverData;
    }

    get studios() {
        return StudioModule.studios;
    }

    get studio() {
        return StudioModule.studio;
    }

    get categories() {
        return CategoryModule.categories;
    }

    get isAdmin() {
        return !this.baseOptions.noAdmin && (this.serverData.isAdmin || StudioModule.studio.isAdmin);
    }

    get isOwner() {
        return !this.baseOptions.noOwner && (this.isAdmin || StudioModule.studio.isOwner);
    }

    get isTeamMember() {
        return !this.baseOptions.noOwner && this.serverData.isTeamMember;
    }

    created(key: string, useExistingStudio = false, requireStudio = false) {
        this.baseKey = key;
        this.useExistingStudio = useExistingStudio;
        this.requireStudio = requireStudio;
    }

    mounted() {
        if (this.useExistingStudio || this.requireStudio) {
            this.alias = Browser.getRouteParam(this.$route, 'studio', null);
            //Debug.log('studioPageBase mounted', this.baseKey, this.alias);
        }

        this.load();
    }

    async mountedAsync() {
        if (this.useExistingStudio || this.requireStudio) {
            this.alias = Browser.getRouteParam(this.$route, 'studio', null);
            //Debug.log('studioPageBase mounted', this.baseKey, this.alias);
        }

        try {
            var promises = [];
            promises.push(StudioModule.fetchStudios(this.baseKey));
            promises.push(CategoryModule.fetchCategories(this.baseKey));
            await Promise.all<any>(promises);
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('studioPageBase', message);
        }

        this.load();
    }

    beforeDestroy() {
        if (this.alias && this.studio.studio == this.alias) {
            this.alias = null;
            StudioModule.selectStudio(null);
        }
    }

    async load() {
        try {
            if (this.alias && !Util.stringCompare(this.studio.studio, this.alias)) {

                let studio = this.studios.find(item => Util.stringCompare(item.studio, this.alias));
                if (studio) {
                    this.alias = studio.studio;

                    Debug.log('studioPageBase load loginSession', studio.studio);
                    // Log in session, since studios may have already been fetched.
                    await PlayerApi.loginSession(studio.studio);

                    StudioModule.selectStudio(studio.studio);
                }
            }

            this.onLoaded(true);

        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('studioPageBase', message);

            if (this.requireStudio) {
                Util.showError('load failed  error: ' + message);
                PlayerUtil.redirectToError(this.$router);
            }
            this.onLoaded(false);
        }
    }

    protected onLoaded(loaded: boolean) {
        if (!loaded) {
            Debug.log('studioPageBase onLoaded failed', this.baseKey);
        }
    }

    async checkStudioAccess(studio: string) {
        try {
            let response = await PlayerApi.checkStudioAccess(studio);

            if (response.hasAccess) {
                Debug.log('checkStudioAccess user has access', this.baseKey, studio);

                PlayerUtil.redirectToView(this.$router, '/Studio', studio);
                return true;
            }

            Debug.log('checkStudioAccess - no access to', studio, response);
            return false;

        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('checkStudioAccess error', message);
            return false;
        }
    }

    getStudioCategory() {
        if (!this.categories.length)
            return Globals.NewCategory;

        let category = this.categories.find(item => item.name == this.studio.category);
        if (category)
            return category;
        else
            return this.categories[0];
    }
}
