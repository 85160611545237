/**
 * Studio store - using vuex-module-decorators
 * Reference https://championswimmer.in/vuex-module-decorators/pages/core/actions.html
 * 
 * Action - async operation that fetches the store data
 * Mutation - synchronus operation that actually changes the store property
 * MutationAction - async operation that fetches the store data and changes the store property
 * 
 * ** this ** considerations:
 *      1. this is not a true class pointer, but instead a store object containing Module getters only
 *      2. Use this.commit to immediately commit
 *      3. Actions cannot reference Module properties via this without consequences.
 *      3. MutationAction cannot reference Module properties via this without consequences.
 *      
 */
import { EventBus } from '@/support/eventBus';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import store from '@/store/store';
import { Module, VuexModule, Mutation, Action, MutationAction, getModule } from 'vuex-module-decorators'
import { orderBy, sortedIndexBy } from 'lodash';
import { ServerDataModule } from './serverData';

@Module({ dynamic: true, store, name: 'studio' })
export class Studio extends VuexModule {
    studio: SUR.AzureStudioDto = Globals.NewStudio;
    studios: SUR.AzureStudioDto[] = [];

    @Mutation
    selectStudio(alias: string) {
        let studio = this.studios.find(item => item.studio == alias);
        this.studio = studio || Globals.NewStudio;
        Debug.log('studio selected studio', this.studio.studio);
        return this.studio;
    }

    @MutationAction({ mutate: ['studios'] })
    async fetchStudios(method: string): Promise<any> {
        try {
            if (!ServerDataModule.serverData.isSignedIn) {
                return {
                    studios: []
                };
            }

            Debug.log('fetchStudios', method);

            let data = <SUR.AzureStudioDto[]>await PlayerApi.studioList();
            data = orderBy(data, ['title']);

            (<any>this).commit('commit_studios', data);

            return {
                studios: data
            };
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('Studios - fetchStudios Failed aquire studios: ' + message);

            return {
                studios: []
            };
        }
    }

    /*****************************************************************
     * Studio Actions
     *
     *****************************************************************
     */

    @Action({ commit: 'commit_create_studio' })
    async createStudio(studio: SUR.AzureStudioDto) {
        try {
            if (!ServerDataModule.serverData.isSignedIn)
                return null;

            Debug.log('createStudio', studio.studio, studio);
            return PlayerApi.createStudio(studio);
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('createStudio', studio.studio, message);
            throw message;
        }
    }

    @Action({ commit: 'commit_edit_studio' })
    async editStudio(studio: SUR.AzureStudioDto) {
        try {
            if (!ServerDataModule.serverData.isSignedIn)
                return null;

            Debug.log('editStudio', studio.studio, studio);
            return await PlayerApi.editStudio(studio);
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('editStudio', studio.studio, message);
            throw message;
        }
    }

    @Action({ commit: 'commit_delete_studio' })
    async turnOffStudio(studio: SUR.AzureStudioDto) {
        try {
            if (!ServerDataModule.serverData.isSignedIn)
                return null;

            Debug.log('turnOffStudio', studio.studio, studio);
            return await PlayerApi.turnOffStudio(studio);
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('turnOffStudio', studio.studio, message);
            throw message;
        }
    }

    /*****************************************************************
     * Studio Mutations
     *
     *****************************************************************
     */

    @Mutation
    private commit_studios(studios: SUR.AzureStudioDto[]) {
        this.studios.splice(0, this.studios.length, ...studios);
        Debug.log('studio commit_studios', this.studios.length);
    }

    @Mutation
    private commit_create_studio(studio: SUR.AzureStudioDto) {
        Debug.log('commit_create_studio', studio.studioId, studio.studio);

        let found = this.studios.find(item => item.studioId == studio.studioId);

        // Sometimes store executes a double commit
        if (found) {
            Debug.log('commit_create_studio already exists', studio.studioId, studio.studio, found.studioId, found.studio);
            Object.assign(found, studio);
        }
        else {
            let idx = sortedIndexBy(this.studios, studio, item => item.title);
            if (idx != -1) {
                this.studios.splice(idx, 0, studio);
            } else {
                this.studios.push(studio);
            }
        }

        EventBus.Instance.$emit(EventBus.StudiosChanged, studio);

        return {
            studios: this.studios
        };
    }

    @Mutation
    private commit_edit_studio(studio: SUR.AzureStudioDto) {
        Debug.log('commit_edit_studio', studio.studioId, studio.studio);

        let found = this.studios.find(item => item.studioId == studio.studioId);

        if (!found) {
            Debug.error('commit_edit_studio missing studio', studio.studioId, studio.studio);
            throw "Missing studio " + studio.title;
        }

        Object.assign(found, studio);

        EventBus.Instance.$emit(EventBus.StudiosChanged, studio);

        return {
            studios: this.studios
        };
    }

    @Mutation
    private commit_delete_studio(studio: SUR.AzureStudioDto) {
        Debug.log('commit_delete_studio', studio.studioId, studio.studio);

        let found = this.studios.find(item => item.studioId == studio.studioId);

        if (!found) {
            Debug.error('commit_delete_studio missing studio', studio.studioId, studio.studio);
            throw "Missing studio " + studio.title;
        }

        let idx = this.studios.indexOf(found);
        this.studios.splice(idx, 1);
        EventBus.Instance.$emit(EventBus.StudiosChanged, studio);

        return {
            studios: this.studios
        };
    }
}

export const StudioModule = getModule(Studio);
Debug.setDebugModule('Studio', StudioModule);
