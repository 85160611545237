import Vue from 'vue';
import VueRouter from 'vue-router';
import Globals from './globals';

Vue.use(VueRouter);

const homeSettings = {
    component: 'settings',
    ownerRequired: true
};

const studioSettings = {
    component: 'studioSettings',
    ownerRequired: true
};

export const routes = [
    {
        path: '/',
        component: ServerData.isTeamMember ? () => import('@/views/teamStudioList/teamStudioList.vue') : () => import('@/views/studioList/studioList'),
        display: Globals.XboxResearch,
        meta: {
            title: Globals.XboxResearch,
            settings: homeSettings
        }
    },
    {
        path: '/Studio/:studio',
        component: () => import('@/views/playerPage/playerPage.vue'),
        display: 'Portal',
        meta: {
            title: 'Portal',
            settings: studioSettings
        }
    },
    {
        path: '/PendingApprovals',
        component: () => import('@/views/pendingApprovals/pendingApprovals.vue'),
        display: 'Pending Approvals',
        meta: {
            title: 'Pending Approvals',
            settings: homeSettings
        }
    },
    {
        path: '/Permissions',
        component: () => import('@/views/permissionsReport/permissionsReport.vue'),
        display: 'Permissions',
        meta: {
            title: 'Permissions',
            settings: homeSettings
        }
    },
    {
        path: '/Permissions/:studio',
        component: () => import('@/views/permissionsReport/permissionsReport.vue'),
        display: 'Permissions',
        meta: {
            title: 'Permissions',
            settings: homeSettings
        }
    },
    {
        path: '/Uploads',
        component: () => import('@/views/uploadsReport/uploadsReport.vue'),
        display: 'Uploads',
        meta: {
            title: 'Uploads',
            settings: homeSettings
        }
    },
    {
        path: '/Uploads/:studio',
        component: () => import('@/views/uploadsReport/uploadsReport.vue'),
        display: 'Uploads',
        meta: {
            title: 'Uploads',
            settings: homeSettings
        }
    },
    {
        path: '/Usage',
        component: () => import('@/views/usageReport/usageReport.vue'),
        display: 'Usage',
        meta: {
            title: 'Usage',
            settings: homeSettings
        }
    },
    {
        path: '/Usage/:studio',
        component: () => import('@/views/usageReport/usageReport.vue'),
        display: 'Usage',
        meta: {
            title: 'Usage',
            settings: homeSettings
        }
    },
    {
        path: '/Videos',
        component: () => import('@/views/videosReport/videosReport.vue'),
        display: 'Videos',
        meta: {
            title: 'Videos',
            settings: homeSettings
        }
    },
    {
        path: '/Videos/:studio',
        component: () => import('@/views/videosReport/videosReport.vue'),
        display: 'Videos',
        meta: {
            title: 'Videos',
            settings: homeSettings
        }
    },

    // ------------------------
    // UnSecure routes
    // ------------------------

    {
        path: '/RequestAccess/:studio',
        component: () => import('@/views/requestAccess/requestAccess.vue'),
        display: 'Request Access',
        meta: { title: 'Request Access', hidePrivacy: true, unSecure: true }
    },
    {
        path: '/AwaitingApproval/:studio',
        component: () => import('@/views/awaitingApproval/awaitingApproval.vue'),
        display: 'Awaiting Approval',
        meta: { title: 'Awaiting Approval', unSecure: true }
    },
    {
        path: '/ConfirmEmailError/:id',
        component: () => import('@/views/confirmEmailError/confirmEmailError.vue'),
        display: 'Confirm Email Error',
        meta: { title: 'Confirm Email Error', unSecure: true }
    },
    {
        path: '/Login',
        component: () => import('@/views/login/login.vue'),
        display: 'Sign In',
        meta: { title: 'Sign In', unSecure: true }
    },
    {
        path: '/Expired',
        component: () => import('@/views/expired/expired.vue'),
        display: 'Expired Session',
        meta: { title: 'Expired Session', unSecure: true }
    },
    {
        path: '/NotAuthorized',
        component: () => import('@/views/notAuthorized/notAuthorized.vue'),
        display: 'Not Authorized',
        meta: { title: 'Not Authorized', unSecure: true }
    },
    {
        path: '*',
        component: () => import('@/views/error/error.vue'),
        display: 'Error',
        meta: { title: 'Error', unSecure: true }
    },

];

export default new VueRouter({
    mode: 'history',
    base: '/',
    routes: routes,
    linkActiveClass: '',
    linkExactActiveClass: 'router-active'
});
