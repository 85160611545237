import { Module, VuexModule, Mutation, Action, MutationAction, getModule } from 'vuex-module-decorators'
import { EventBus } from '@/support/eventBus';
import Globals from '@/globals';
import LocalData from '@/support/localStorage';
import store from '@/store/store';

@Module({ dynamic: true, store, name: 'serverData' })
export class ServerDataStore extends VuexModule {
    serverData = Globals.NewServerViewModel;

    /*****************************************************************
     * ServerData mutations
     *
     *****************************************************************
     */

    @Action({ commit: 'commit_edit_serverData' })
    editServerData(serverData: SUR.ServerViewModel) {
        return serverData;
    }

    @Mutation
    private commit_edit_serverData(serverData: SUR.ServerViewModel) {
        Object.assign(this.serverData, serverData);

        LocalData.save('serverData', serverData);
        EventBus.Instance.$emit(EventBus.ServerDataChanged, serverData);

        return {
            serverData: this.serverData
        };
    }
}

export const ServerDataModule = getModule(ServerDataStore);
Debug.setDebugModule('ServerDataModule', ServerDataModule);
