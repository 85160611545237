import LocalData from './localStorage';
import { ServerDataModule } from '@/store/serverData';

export default class SigninUtil {

    public static getProvider() {
        let signin = LocalData.get(LocalData.SigninKey, null);
        if (signin && signin.provider)
            return signin.provider;

        return null;
    }

    public static saveProvider(provider) {
        Debug.log('SigninUtil saveProvider', provider);
        LocalData.save(LocalData.SigninKey, { provider: provider });
    }

    public static clear() {
        LocalData.remove(LocalData.SigninKey);
    }

    public static redirectToProvider(redirectUrl: string) {
        redirectUrl = redirectUrl || '%2F';

        let signin = LocalData.get(LocalData.SigninKey, null);
        if (!signin || !signin.provider)
            return false;

        Debug.log('redirectToProvider', signin.provider, redirectUrl);

        let urlParam = '';
        switch (signin.provider) {
            case "Azure Active Directory":
                urlParam = 'aad';
                break;

            case "Microsoft":
                urlParam = 'microsoftaccount';
                break;

            default:
                return false;
        }

        let url = window.location.origin + '/.auth/login/' + urlParam + '?post_login_redirect_url=' + redirectUrl;

        // clear provider - will be set again on successful signin
        SigninUtil.saveProvider(null);

        Debug.log('redirectToProvider', signin.provider, url);
        window.location.href = url;
        return true;
    }

    private static getRedirectUrl(redirectUrl: string) {
        if (!redirectUrl || redirectUrl == '/' || redirectUrl == '%2F') {
            return '';
        }

        // Trim trailing /
        while (redirectUrl && redirectUrl.endsWith('/')) {
            redirectUrl = redirectUrl.substr(0, redirectUrl.length - 1);
        }

        while (redirectUrl && redirectUrl.startsWith('/')) {
            redirectUrl = redirectUrl.substr(1);
        }

        // prevent double slash
        redirectUrl.replace('%2F/', '/').replace('/%2F', '/');
        return redirectUrl;
    }

}

