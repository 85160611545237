import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import Browser from '@/support/browser';
import Globals from '@/globals';
import SigninUtil from '@/support/signinUtil';
import StudioPageBase from '@/support/studioPageBase';
import TopBar from '@/components/topBar/topBar.vue';
import { ServerDataModule } from '@/store/serverData';
import { StudioModule } from '@/store/studio';
import { EventBus } from '@/support/eventBus';
import { IShowSettings } from '@/interfaces';
import { IWizardManager } from '@/interfaces';
import PlayerUtil from '@/support/playerUtil';

Vue.component('top-bar', TopBar);

@Component
export default class AppComponent extends StudioPageBase {
    get serverData() {
        return ServerDataModule.serverData;
    }

    options = {
        allData: Browser.getBoolParam('all', false) || Browser.getBoolParam('allData', false),
        clearSignin: Browser.getBoolParam('clearSignin', false),
        limit: Browser.getIntParam('limit', 0),
        filter: Browser.getParam('filter', null),
        signin: Browser.getBoolParam('signin', false),
        noAdmin: Browser.getBoolParam('noAdmin', false),
        noOwner: Browser.getBoolParam('noOwner', false),
        noTeamMember: Browser.getBoolParam('noTeamMember', false),
    };

    loading = true;
    transitionName = '';
    wizardParent = <any>null;
    wizardVideo = <any>null;
    redirectedView: string = null;

    get notAuthorized() {
        return this.serverData.notAuthorized && !this.$route.meta.unSecure;
    }

    get isAdmin() {
        return !this.options.noAdmin && (this.serverData.isAdmin || StudioModule.studio.isAdmin);
    }

    get isOwner() {
        return !this.options.noOwner && StudioModule.studio.isOwner;
    }

    get isTeamMember() {
        return !this.options.noOwner && this.serverData.isTeamMember;
    }

    get showTopBar() {
        return !this.serverData.hideTopBar;
    }

    get showPrivacy() {
        return !this.$route.meta || !this.$route.meta.hidePrivacy;
    }

    async created() {
        Debug.setDebugModule('baseApp', this);
        super.created('app');

        if (this.options.clearSignin) {
            SigninUtil.clear();
        }

        Debug.log('app created', this.$route.path, 'provider:', SigninUtil.getProvider());
    }

    async mounted() {
        Debug.log('app mounted');

        if (this.redirectToProvider()) {
            return;
        }

        this.$router.beforeEach((to, from, next) => this.routerBeforeEach(to, from, next));
        this.$router.afterEach((to, from) => this.routerAfterEach(to, from));

        EventBus.Instance.$on(EventBus.ShowSettingsWizard, (model: IShowSettings) => {
            this.showSettingsWizard(model);
        });

        await super.mountedAsync();

        this.loading = false;
    }

    protected onLoaded(loaded: boolean) {
        // Save provider on successful signin
        if (loaded && this.studios.length && !this.options.clearSignin) {
            SigninUtil.saveProvider(this.serverData.providerName);
        }
    }

    async routerBeforeEach(to: any, from: any, next: Function) {
        //Debug.log('app', 'router beforeEach', to.path, from.path);

        this.transitionName = 'fade';

        next();
    }

    async routerAfterEach(to: any, from: any) {
        //Debug.log('app', 'router afterEach', to.path, from.path);

        let title = to.meta.title || '';
        if (title == Globals.XboxResearch)
            document.title = Globals.XboxResearch;
        else
            document.title = title + ' - ' + Globals.XboxResearch;
    }

    showSettingsWizard(model: IShowSettings) {
        Debug.log('showSettingsWizard', model.name, model.video);
        let wizard = <IWizardManager><any>this.$refs.settingsWizard;
        if (!wizard)
            return;

        this.wizardParent = model.parent;
        this.wizardVideo = model.video;

        wizard.navigate(model);
    }

    redirectToProvider() {
        if (ServerData.isSignedIn || !this.options.signin) {
            return false;
        }

        let returnUrl = Browser.getParam('ReturnUrl', null);
        if (!returnUrl) {
            return false;
        }

        return SigninUtil.redirectToProvider(returnUrl);
    }
}
