function encode(s: string) {
    return encodeURIComponent(s);
}

function decode(s: string) {
    return decodeURIComponent(s);
}

function parseValue(s: string) {
    if (s.indexOf('"') === 0) {
        // This is a quoted cookie as according to RFC2068, unescape...
        s = s.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\');
    }

    try {
        let pluses = /\+/g;
        // Replace server-side written pluses with spaces.
        // If we can't decode the cookie, ignore it, it's unusable.
        // If we can't parse the cookie, ignore it, it's unusable.
        s = decodeURIComponent(s.replace(pluses, ' '));
        return JSON.parse(s);
    } catch (e) {
        return null;
    }

}

export default class LocalData {
    public static SigninKey = "signin";

    static save(key: string, value: any) {
        try {
            let valueToSave = encode(JSON.stringify(value));
            window.localStorage.setItem(encode(key), valueToSave);
            //console.log('LocalData.save', encode(key), window.localStorage.getItem(encode(key)));
        } catch (e) {
            return;
        }
    }

    static get(key: string, defaultValue: any = null): any {
        try {
            let value = window.localStorage.getItem(encode(key));
            //console.log('LocalData.get', encode(key), value, parseValue(decode(value)));
            if (value) {
                value = parseValue(decode(value));
                if (value && value != 'null')
                    return value;
            }

            return defaultValue;
        } catch (e) {
            return null;
        }
    }

    static getBool(key: string, defaultValue: boolean = false) {
        defaultValue = defaultValue || false;
        const value = LocalData.get(key, defaultValue);
        if (value && value != undefined)
            return value === true || value == 'true' || value == '1';
        return false;
    }

    static remove(key: string) {
        window.localStorage.removeItem(encode(key));
    }

};
